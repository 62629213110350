import { useApp } from "../../hooks/useApp";
import { Button } from "../Button";

import styled from "styled-components";

const BodyContainer = styled.div`
  min-height: 550px;
  height: 100%;
  position: relative;

  @media (max-width: 440px) {
    min-height: 440px;

    .dragon-tiger-mobile {
      width: 70%;
    }

    .dice-icon {
      top: 30%;
    }
  }

  @media (max-width: 430px) {
    min-height: 460px;
  }

  @media (max-width: 390px) {
    min-height: 480px;
  }

  @media (max-width: 376px) {
    min-height: 415px;

    .dragon-tiger-mobile {
      width: 80%;
    }

    .dice-icon {
      top: 30%;
    }
  }

  @media (max-width: 361px) {
    min-height: 440px;

    .dragon-tiger-mobile {
      width: 80%;
    }

    .dice-icon {
      top: 30%;
    }
  }

  .dragon-icon-desktop {
    position: absolute;
    max-width: 300px;
    width: 100%;
    top: 7%;
    left: 0%;

    @media (max-width: 1920px) {
      max-width: 300px;
      left: 0%;
    }

    @media (max-width: 1660px) {
      max-width: 300px;
      left: 0%;
    }

    @media (max-width: 1440px) {
      max-width: 290px;
      left: -15%;
    }

    @media (max-width: 1025px) {
      width: 290px;
      left: -30%;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .dragon-tiger-mobile {
    display: none;

    @media (max-width: 768px) {
      display: flex;
    }
  }

  .tiger-icon-desktop {
    max-width: 367px;
    width: 367px;
    position: absolute;
    right: 1%;
    top: 2%;

    @media (max-width: 1440px) {
      width: 327px;
    }

    @media (max-width: 1025px) {
      width: 250px;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .dice-icon {
    top: 40%;
  }

  .body-content {
    margin-top: 118px;

    .dice-table {
      min-width: 140%;
    }

    @media (max-width: 1921px) {
      .dice-table {
        min-width: 140%;
      }
    }

    @media (max-width: 1660px) {
      .dice-table {
        min-width: 140%;
      }
    }

    @media (max-width: 1441px) {
      .dice-table {
        min-width: 140%;
      }
    }

    @media (max-width: 1025px) {
      .dice-table {
        min-width: 120%;
      }
    }

    @media (max-width: 768px) {
      .dice-table {
        min-width: 100%;
      }
    }

    @media (max-width: 440px) {
      margin-top: 70px;

      .dice-table {
        max-width: 330px;
        width: 330px;
        min-width: 330px;
      }
    }

    @media (max-width: 391px) {
      margin-top: 90px;

      .dice-table {
        width: 95%;
      }
    }

    @media (max-width: 376px) {
      margin-top: 60px;

      .dice-table {
        max-width: 300px;
        width: 300px;
        min-width: 300px;
      }
    }

    @media (max-width: 361px) {
      margin-top: 80px;

      .dice-table {
        width: 70%;
      }
    }
  }
`;

export const Body = () => {
  const { rollDice, diceBoxRef, diceCanvasRef, loading, round } = useApp();

  return (
    <div className="body-offer">
      <BodyContainer className="dice-game-container relative flex w-full justify-center min-h-[580px]">
        <img
          className="dragon-tiger-mobile absolute"
          src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/ad6a9988-5633-4a54-a115-889a204b7000/public"
        />
        <img
          className="dice-icon absolute right-0 top-[40%] z-[1]"
          src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/9c2adb5c-d232-45db-ef8c-ec381e8a9300/public"
        />

        <img
          className="dragon-icon-desktop"
          src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/63f59636-8683-47b6-8767-11f31c3a4000/public"
        />

        <img
          className="tiger-icon-desktop"
          src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/94e96076-5474-49cc-6caa-0f1c29d3a700/public"
        />

        <div className="body-content flex justify-center items-center absolute max-w-[360px] w-full">
          <img
            className="dice-table"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/c66ea548-3158-4ce1-4cc5-1653fbceaf00/public"
          />

          <div
            id="dice-box"
            ref={diceBoxRef}
            className="flex justify-center items-center absolute z-[98] w-full max-w-[85%] min-h-[85%] rounded-[15%]"
          >
            <div
              className="absolute z-[99] top-[-45px] flex gap-2 rounded-lg px-4 py-1"
              style={{
                background:
                  "linear-gradient(to right, #ff8b32, #e96500, #ba5203)",
                border: "2px solid #ff8910",
                fontFamily: "Gotham-Black",
              }}
            >
              <p className="text-white">Tentativas: </p>{" "}
              <strong className="text-white">{2 - round}</strong>
            </div>
            <canvas
              id="dice-canvas"
              ref={diceCanvasRef}
              className="relative bg-blue-500 absolute z-[99] w-0 h-0 rounded-[15%]"
            ></canvas>
          </div>
        </div>
      </BodyContainer>

      <div className="play-button-container flex justify-center z-20">
        <Button
          className="py-2 px-10 gap-2 text-lg uppercase"
          disabled={loading}
          onClick={loading ? () => {} : rollDice}
        >
          Jogue os dados
          {loading ? (
            <img
              className="animate-spin "
              src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/f0de6a09-85e6-414d-b06d-1d8960073e00/public"
            />
          ) : (
            <img
              className="w-8 h-8"
              src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/4a7b068b-fc93-48d0-4905-eecace5ca900/public"
            />
          )}
        </Button>
      </div>
    </div>
  );
};
