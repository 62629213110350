import { ReactNode } from "react";
import { useSearchParams } from "react-router-dom";

interface OfferProps {
  target?: string;
  children?: ReactNode;
}

export const Offer = ({
  target = "https://casspx.com",
  children,
}: OfferProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get("_token");

  return (
    <a
      href={`${target}/?_lp=1&_token=${token}`}
      className="flex flex-1 gap-3 items-center"
    >
      {children}
    </a>
  );
};

//https://casspx.com/?_lp=1&_token=uuid_288h85s1h6vj_288h85s1h6vj6660622b09a106.77370198
