import { useState } from "react";
import styled from "styled-components";
import { Timer } from "../Timer";

const HeaderContainer = styled.header`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  background: linear-gradient(to right, #e96500, #ff8a13);
  z-index: 99;

  @media (max-width: 376px) {
    min-height: 2.5rem;
  }

  p {
    font-family: "Gotham-Black";
    font-size: 12px;

    @media (max-width: 320px) {
      font-size: 10px;
    }
  }
`;

export const Header = () => {
  const currentDate = new Date();
  const updatedDate = currentDate.setHours(currentDate.getSeconds() + 60);

  const [timerState, setTimerState] = useState(new Date(updatedDate));

  return (
    <HeaderContainer className="flex min-h-[3rem] items-center justify-center">
      <p className="flex h-fit uppercase text-white tracking-wide">
        essa oportunidade se encerrará em:
        <span className="text-black ml-1">
          <Timer expiryTimestamp={timerState} />
        </span>
      </p>
    </HeaderContainer>
  );
};
