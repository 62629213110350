// Core testing commit
import React from "react";

// libs
import styled from "styled-components";

// Components
import { Header } from "./components/Header";
import { Headline } from "./components/Headline";
import { Body } from "./components/Body";
import { Footer } from "./components/Footer";
import { Modal } from "./components/Modal";

// Hooks
import { useApp } from "./hooks/useApp";

// Styles
const AppContainer = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 100vh;
  background-image: url("https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/fbb75827-7293-4ee8-d468-96f89a957a00/public");
  background-position: center;
  background-size: cover;

  @media (max-width: 768px) {
    background-image: url("https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/d730ad1a-5c0e-40d3-389b-13d4720b6c00/public");
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: start;
  }

  .headline-body {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 2.5rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    @media (max-width: 376px) {
      padding-top: 1.5rem;
    }

    .headline {
      font-family: "Gotham-Black";
      text-transform: uppercase;
      color: white;
      flex: 1;
      text-align: start;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .body-offer {
      flex: 1;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .play-button-container {
        position: absolute;
        bottom: 10%;
        z-index: 999;

        @media (max-width: 768px) {
          position: unset;
          bottom: unset;
          margin-bottom: 2rem;
        }

        @media (max-width: 425px) {
          * {
            font-size: 0.75rem;
          }

          button {
            scale: 1.2;
          }
        }
      }
    }
  }
`;

export default function App() {
  const { modalIsOpen } = useApp();
  return (
    <AppContainer className="flex flex-col">
      <Header />

      <div className="headline-body">
        <Headline />

        <Body />
      </div>

      <Footer />

      {modalIsOpen && <Modal />}
    </AppContainer>
  );
}
//
