import styled from "styled-components";

const FooterContainer = styled.footer`
  min-width: 100vw;
  bottom: 0;
  background: rgba(0, 7, 13, 0.75);
  z-index: 99;

  position: fixed;

  @media (max-width: 768px) {
    position: unset;
    margin-top: auto;
  }

  p {
    font-family: "Gotham-Medium";
    font-size: 0.5rem;
  }
`;

export const Footer = () => {
  return (
    <FooterContainer className="flex flex-col mt-auto items-center pt-5 pb-3">
      <img
        className="max-w-[186px] mb-4"
        src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/73f878e2-7c57-4f67-b349-343d33704700/public"
      />
      <p className="text-white">Todos os direitos reservados</p>
    </FooterContainer>
  );
};
